import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const creds = JSON.parse(process.env.REACT_APP_FIREBASE_CREDENTIALS);

const app = initializeApp(creds);


const auth = getAuth(app);
export {auth};
export default app;

